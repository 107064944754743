import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';

export const faqPageQuery = graphql`
  query ContactUsSubmittedPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const FAQPage = () => {
  const { navigate } = useI18next();

  useEffect(() => {
    navigate('/support/consumer/', { replace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default FAQPage;
